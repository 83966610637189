/**
 * 请求模块
 */
import axios from 'axios'
import store from '../store/index'
import { PRO_BASE_URL, DEV_BASE_URL } from './Url'
import Constant from "../common/Constant"
import { Message } from 'element-ui';



const request = axios.create({
    baseURL: Constant.MODE === Constant.PRO_MODE ? PRO_BASE_URL : DEV_BASE_URL, // 基础路径
})

// 请求拦截器
request.interceptors.request.use(function (config) {

    const { user } = store.state
    if (user) {
        config.headers.Authorization = `${user.accessToken}`
    }
    config.headers['USER-MODE'] = 'web_client'
    return config
}, function (error) {
    // Do something with request error
    return Promise.reject(error)
})

// 响应拦截器
request.interceptors.response.use(function (response) {
    // Do something with response data
    const { data } = response
    if (!data.code) {
        return data
    }
    return Promise.reject(data)
}, async function (error) {
    const status = error.response.status
    if (status === 400) {
        // 客户端请求参数错误
        Message.error('客户端请求参数异常')
    } else if (status === 401) {
        Message.error('请重新登录')
    } else if (status >= 500) {
        // 服务端异常
        Message.error('服务端异常，请稍后重试')
    }
    // 抛出异常
    return Promise.reject(error)
})



// 导出
export default ({ method, params, data, ...config }) => {
    const _data = params || data
    return request({
        ...config,
        method,
        params: method === "GET" ? _data ? { ..._data } : "" : "",
        data: method !== "GET" ? data ? { ...data } : "" : ""
    })
}
