import houseServer from "../services/houseServer.js"
import store from '../store/index'
import router from "../router/index"
import { setItem } from "../utils/storage"
import Constant from "../common/Constant.js";


export default {
    /**
     * @param { Promise } 传进去的请求函数
     * @param { Object= } errorExt - 拓展错误对象
     * @return { Promise } 返回一个Promise
    */
    to(promise, errorExt) {
        return promise
            .then(data => [null, data])
            .catch(err => {
                if (errorExt) {
                    const parsedError = Object.assign({}, err, errorExt)
                    return [parsedError, undefined]
                }

                return [err, undefined]
            })
    },
    throttle(fn, delay = 200) {
        let prev = 0, timer = null
        return (...args) => {
            let now = +new Date()
            if (now - prev > delay) {
                prev = now
                fn.apply(this, args)
            } else {
                timer && clearTimeout(timer)
                timer = setTimeout(() => {
                    prev = now
                    fn.apply(this, args)
                }, delay)
            }
        }
    },
    animate(obj, target, callback) {
        obj.timer && clearInterval(obj.timer)
        obj.timer = setInterval(function () {
            let step = (target - window.pageYOffset) / 10
            step = step > 0 ? Math.ceil(step) : Math.floor(step)
            if (window.pageYOffset === target) {
                clearInterval(obj.timer)
                callback && callback()
            } else {
                window.scroll(0, window.pageYOffset + step)
            }
        }, 15)
    },
    animateX(obj, target, callback) {
        obj?.timer && clearInterval(obj.timer)
        if (obj) {
            obj.timer = setInterval(function () {
                let step = (target - obj.offsetLeft) / 10
                step = step > 0 ? Math.ceil(step) : Math.floor(step)
                if (obj.offsetLeft == target) {
                    clearInterval(obj.timer)

                    callback && callback()
                } else {
                    obj.style.left = obj.offsetLeft + step + 'px'
                    // callback && callback()
                }
            }, 15)
        }

    },
    isNumber(value) {
        return value.replace(/[^\d.^]/g, '')
    },
    // 数字加,
    transforNumber(num) {
        num = parseInt(num)
        if (num > 1000) {
            let tempStr = num + ""
            let resStr = ""
            let count = 0
            for (let i = tempStr.length - 1; i >= 0; i--) {
                resStr = tempStr.charAt(i) + resStr
                if (++count === 3 && i) {
                    resStr = "," + resStr
                    count = 0
                }
            }
            return resStr
        }
        return num

    },
    /* 前2后2 */
    mobileTo2_2(mobile) {
        if (mobile) {
            return mobile.replace(/^(\d{2})\d{7}(\d{2})$/, "$1****$2")
        } else {
            return ""
        }
    },

    // 获取定位
    getLocation(callback) {
        const LocalCity = new window.BMap.LocalCity();
        //经纬度换取具体地理位置
        LocalCity.get(async ({ center }) => {
            const { lat, lng } = center;
            const [err, { data }] = await this.to(houseServer.getLocalByCoordinate({ location: "https://apis.map.qq.com/ws/geocoder/v1/?location=" + lat + "," + lng }))
            if (err) {
                console.log("getLocalByCoordinate :>>", err);
            }
            if (data) {
                const { result: { ad_info } } = JSON.parse(data.result);
                //处理特殊城市cityCode
                this.processCityCode(ad_info);
                const location = {
                    cityCode: ad_info.city_code,
                    cityName: ad_info.city,
                    latitude: ad_info.location.lat,
                    longitude: ad_info.location.lng
                }
                store.commit("saveLocation", location)
                setItem(Constant.IS_GET_LOCATION, true)
                setTimeout(() => {
                    router.go(0)
                }, 500)
            } else {
                console.log("定位失败!");
            }
            callback?.()

        })

    },

    processCityCode(ad_info) {
        if (ad_info.city == "峨眉山市" || ad_info.district == "峨眉山市") {
            ad_info.city_code = "156511181";
        } else if (ad_info.city == "眉山市" || ad_info.district == "眉山市") {
            ad_info.city_code = "156511700";
        }
    },
    setCompanyGuid(params) {
        const { cityCompany } = store.state
        const companyGuid = Constant.DEFAULT_COMPANY_GUID
        params.companyGuid = companyGuid || cityCompany?.cityCompanyGuid || cityCompany?.headCompanyGuid || ''
        params.headCompanyGuid = companyGuid || cityCompany?.headCompanyGuid || ''
    },

}



