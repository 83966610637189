
// const DEV_BASE_URL = 'http://192.168.1.67:8000';//development
const DEV_BASE_URL = 'https://test.api.fzhitong.cn';//development
const PRO_BASE_URL = 'https://api.fzhitong.cn';//production

const API_URL = {
    ESTATE_LIST_GET: '/api/house/customer/house/estate', //二手房/租房搜索
    NEW_ESTATE_PAGE_LIST: '/api/real/estate/customer/page/v2', //新房列表
    SALE_HOUSE: '/api/real/customer/realhouse/page/v2', //房源列表
    SECOND_HOUSE_PARAM: "/api/real/customer/realhouse/parameter/v2", // 房源搜索参数
    ESTATE_PARAMETER_GET: '/api/house/customer/house/estate/parameter',//小区搜索参数
    NEW_ESTATE_PARAMS: '/api/real/estate/customer/parameter', //新房搜索参数
    ESTATE_LIST_PAGE: '/api/house/customer/house/estate/page', //小区列表
    CUSTOMER_ARTICLE: "/api/article/customer/article/cityCode", // 看点
    ALL_CITY_TREE: '/api/wechat/location/allCity/tree',//城市列表
    USER_LOGIN: "/api/auth/appClientAuthorization/accesstoken/appClient", //登录
    SEND_LOGIN_CODE: "/api/wechat/vaild/code/send/appClientLogin", //获取验证码
    DEMAND_HOUSE_FORM: '/api/real/customer/buyHouse', //卖房委托
    COLLECTION_PAGE: '/api/real/customer/attention/page', //二手房关注列表
    ADD_COLLECTION: '/api/real/customer/attention', // 添加收藏
    CANCEL_COLLECTION: '/api/real/customer/attention/cancel', // 取消收藏
    XIAOZHI_RESETPASS_SEND_CODE: '/api/wechat/vaild/code/send/appClientResetPassword', //发送重置密码验证码
    RESET_PASSWORD: '/api/auth/appClientAuthorization/resetPassword', //小智客户端重置密码
    GET_COLLECTION_STATUS: '/api/real/customer/attention?houseGuid={houseGuid}&tradeType={tradeType}', // 查询收藏状态
    GETLOCALBYCOORDINATE: '/api/wechat/wx/getLocal/',
    ESTATE_ATTENTION: "/api/real/estate/attention", // 楼盘关注







    CITY_LIST: '/api/wechat/location/simple/city/tree/v2',
    CITY_LIST_V3: '/api/wechat/location/simple/city/tree/v3',
    HOUSE_PARAMS: '/api/real/flow/house/param',

    REALHOUSE_BYID_GET: '/api/real/customer/realhouse/{guid}',//真房源详情
    RESOURCE_GET: '/api/house/resource/{guid}',//真房源图片
    TAKE_FEED_BACK: '/api/real/house/takeLookFeedback',//真房源带看反馈
    ESTATE_GET_BYUD: '/api/house/customer/house/estate/{guid}',//真房源小区详情
    REALHOUSE_DYNAMIC_GET: '/api/real/customer/realhouse/dynamic',//真房源动态
    HOUSE_HISTORY_DEAL: '/api/house/customer/deal/contract/v2',//真房源历史成交
    GET_RECOMMEND_HOUSE: '/api/real/customer/realhouse/recommend',//真房源周边推荐
    REALHOUSE_RECOMMEND_AGENTV3: 'api/real/customer/realhouse/agent/recommendV3',//真房源周边推荐
    GET_RENT_NEIGHBORHOOD: '/api/real/customer/realhouse/page/estate/house',//同小区房源

    CANCEL_HOUSE_ATTENTION: '/api/real/customer/attention/cancel/v2',//取消关注
    ADD_HOUSE_ATTENTION: '/real/customer/attention/v2',//关注
    GET_HOUSE_ATTENTION: '/real/customer/attention/v2',//获取关注
    HOUSE_LIST: '/api/real/flow/sale/map',
    AREA_REGION: '/api/real/flow/area/map',
    STREET_LIST: '/api/house/customer/house/estate/region',
    OLD_ESTATE_INFO: '/api/house/customer/house/estate/',//小区详情
    SAME_ESTATE_HOUSE_LIST: '/api/real/customer/realhouse/page/estate/house',//小区房源
    VIEW_ESTATE_AGENT: '/api/house/customer/house/estate/agent/recommend',//小区带看过得经纪人
    AROUND_ESTATE_LIST: '/api/house/customer/house/estate/recommend/neighborhood',//小区带看过得经纪人
    ORGANIZE_NEIGHBORHOOD_GET: '/api/auth/department/neighborhood',//小区带看过得经纪人
    CUSTOEMR_AGENTHOUSE_DETAIL: '/api/house/customer/agent/house/{guid}',//店铺房源




    REAESTATE_BYID_GET: '/api/real/estate/customer/detail',// 楼盘信息
    HOUSE_TYPE_GET: '/api/real/estate/customer/houseTypes',//获取户型
    NEW_ESTATE_MODEL: '/api/real/estate/customer/houseModel',//获取楼栋
    ESTATE_DYNAMIC: '/api/real/estate/dynamic',//获取动态
    SIMILARITY_ESTATE: '/api/real/flow/estate/similar',//相似楼盘
    ESTATE_AGENT: '/api/real/customer/realhouse/agent/recommendV3',//优选顾问
    ESTATE_PARAMS: '/api/real/estate/agent/parameter',//优选顾问
    NEW_ESTATE_HOUSE_TYPE_DETAIL: '/api/real/estate/customer/houseType/byGuid',//户型详情
    NEW_ESTATE_HOUSE_BIND_MODEL: '/api/real/estate/agent/houseTypeBindModel',//
    NEW_ESTATE_HOUSE_BINDing: '/api/real/estate/agent/estateModel',//

    AGENT_DETAIL: "/api/auth/agent/noAuth/{guid}",
    AGENT_HOUSE: "/api/house/customer/agent/house/v2",
    QR_CODE: "/api/wechat/wx/miniQrCode/v3",
    COMPANY_CONFIG: "/api/auth/company/config", //公司配置
    LOAD_COMPANY_BY_CITY_CODE: '/api/auth/cityConfig?cityCode={cityCode}',
    LOAD_TOP_HOUSE: '/api/real/house/customer/houseTOP/houseTopList',
}

module.exports = {
    API_URL,
    DEV_BASE_URL,
    PRO_BASE_URL
}